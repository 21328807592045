<template>
    <div>
        <div class="align-items-center justify-content-end mx-1 my-3" style="background-color: white; color: #828c9c; height: auto;">
          <CRow class="align-items-center justify-content-end">
            <CCol sm="6" xl="6" >
              <!--

              <CRow>
                <CCol sm="12" xl="12" class="align-items-center">
                  <CButton
                    shape="square"
                    color="add"
                    class="d-flex align-items-center ml-auto"
                    v-c-tooltip="{
                      content: $t('label.bl'),
                      placement: 'top-end'
                    }"
                    @click="toggleContainerAdd()"
                  >
                    <CIcon name="cil-playlist-add"/><span class="ml-1">{{$t('label.nuevo')}}</span>
                  </CButton>
                </CCol>
              </CRow>
              
              -->
            </CCol>
          </CRow>
        </div>
        <div>
          <CRow>
              <CCol col="12" xl="12">
                  <dataTableExtended
                      class="align-center-row-datatable"
                      hover
                      sorter
                      small
                      details
                      column-filter
                      :items="formatedItems"
                      :fields="fields"
                      :noItemsView="tableText.noItemsViewText"
                      :items-per-page="tableText.itemsPerPage"
                      pagination
                  >
                      <template #Status="{ item }">
                        <td class="cell-center text-center">
                          <CBadge :color="getBadge(item.Status)">
                            {{ $t('label.'+item.Status) }}
                          </CBadge>
                        </td>
                      </template>
                      <template #Detalle="{item}">
                        <td class="text-center">
                          <CButton
                            color="edit"
                            square
                            size="sm"
                            class="mr-1"
                            v-c-tooltip="{
                              content: $t('label.edit')+' '+$t('label.bl'),
                              placement: 'top-start'
                              }"
                            @click="toggleContainerEdit(item)"
                        >
                          <CIcon name="pencil" />
                        </CButton>
                        <CButton
                          square
                          size="sm"
                          color="watch"
                          class="mr-1"
                          v-c-tooltip="{
                              content: $t('label.see')+' '+$t('label.document'),
                              placement: 'top-start'
                          }"
                          @click="viewDocument(item)"
                        >
                          <CIcon name="eye" />
                        </CButton>
                        <CButton
                          square
                          size="sm"
                          color="watch"
                          @click="getBl(item)"
                          v-c-tooltip="{
                            content: `${$t('label.see')} ${$t('label.bl')}`,
                            placement: 'top-start'
                          }"
                        >
                          <CIcon name='cil-list' />
                        </CButton>
                      </td>
                    </template>
                </dataTableExtended>
              </CCol>
          </CRow>
        </div>
      <ModalBlContainer
        :modal.sync="ModalBlContainer"
        :ContainerItem="ContainerItem"
        :items ="items"
        :title="titleModal"
        :fgInfo="dataContainer?.FgCertified"
        :isEdit="isEdit"
        @close="closeModal"
        @Update-list="Update"
      />
      <ModalBlDocument
        :modal.sync="ModalBlDocument"
        :title="titleDocumentsModal"
        :documentList="documentList"
        @close="closeModal"
      />
    </div>
  </template>
  <script>
  
  import GeneralMixin from '@/_mixins/general';
  import { mapState } from 'vuex';
  import ModalBlContainer from './modal-bl-container';
  import ModalBlDocument from './modal-bl-document';
  import { DateFormater, removeTags, trimRemoveSpecialCharacters} from '@/_helpers/funciones';
  import { formatMilDecimal } from '@/_validations/validacionEspeciales';

  //DATA-------------------------
  function data() {
    return {
      ModalBlContainer: false,
      ModalBlDocument: false,
      ContainerItem:{},
      titleModal: '',
      titleDocumentsModal: '',
      isEdit: false,
      items: [],
      documentList: [],
    }
  }
  
  //methods
  async function getBLList() {
    if(this.CargoId && this.tabIndex === 0 && this.yardCollapse == 2 && !this.ValidateTpCargoStatus && !this.FgPositioning){
      this.$store.state.yardManagement.loading = true;
      await this.$http.get('YardBlCargolist-by-CargoId',{ CargoId: this.CargoId})
      .then(response => {
        let listado = response.data.data;
        if (listado && listado.length != 0){
            this.items = listado;
        }
      })        
      .finally(() => {
        this.$store.state.yardManagement.loading = false;
      });
    }
    this.$store.state.yardManagement.isSelect = false;
  }

  async function getYardCargo() {
    await this.$http.get('YardCargo-by-Id', { YardCargoId: this.YardCargoId, YardId: this.YardId })
      .then(response => {
        let List = response.data.data;
        if (List&&List.length!=0) {
          if(this.$store.state.yardManagement.dataContainer.YarCargoDispachtId){
            let yardCargoReception = '', yardCargoDispatch = ''; 

            yardCargoReception = this.$store.state.yardManagement.dataContainer.YardCargoId;
            yardCargoDispatch = this.$store.state.yardManagement.dataContainer.YarCargoDispachtId;

            this.$store.state.yardManagement.dataContainer = {...List[0], YardCargoId : yardCargoReception, YarCargoDispachtId: yardCargoDispatch};
          } 
          else{
            this.$store.state.yardManagement.dataContainer = List[0];
          }
          
          if (process.env.VUE_APP_YARD_CARGO_STATUS_RECEPTION == List[0].YardCargoStatus.toUpperCase()) {
            this.$store.state.yardManagement.globalSelectContainer = 
              this.$store.state.yardManagement.globalSelectContainer.map(Select=>{
                return Select.CargoId == this.CargoId ? List[0] : Select;
              });
          }
        }else{
          this.$store.state.yardManagement.dataContainer = {};
          this.$store.state.yardManagement.ListReceivedContainers = [];
          this.$store.state.yardManagement.ListDispatchedContainers = [];
          this.$store.state.yardManagement.ListRecognitionContainers = [];
        }
      })
      .catch( err => {
        this.$store.state.yardManagement.dataContainer = {};
        this.$store.state.yardManagement.ListReceivedContainers = [];
        this.$store.state.yardManagement.ListDispatchedContainers = [];
        this.$store.state.yardManagement.ListRecognitionContainers = [];
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
      })
  }

  function getBl(item) {
    this.$store.state.yardManagement.loading = true;
    this.$http.get("BlCargo-by-Id", { BlCargoId: item.BlCargoId})
    .then(response => {
      let Bl = response?.data?.data?.[0] ?? undefined;
      this.$store.state.yardManagement.BlTabIndex = 0;
      if (Bl) {
        this.$store.state.yardManagement.dataBl = Bl;
        this.$store.state.yardManagement.FgBlNavigation = true;
        this.$store.state.yardManagement.BlCollapse = 1;
        this.$store.state.yardManagement.yardCollapse = 4;
      }
    }).catch( err => {
        this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
  }

  function toggleContainerAdd() {
    this.ModalBlContainer = true;

    this.isEdit = false;
    this.titleModal = this.$t('label.nuevo')+' '+this.$t('label.bl');
  }
  
  function toggleContainerEdit(item) {
    this.ContainerItem = item;
    this.ModalBlContainer = true;
    this.isEdit = true;
    let title = this.ContainerItem.BlNro;
    this.titleModal =  this.$t('label.edit')+' '+this.$t('label.bl')+': '+title;
  }

  function viewDocument(item){
    this.ModalBlDocument = true;
    let title = item.BlNro;
    this.documentList = item.DocumentJson;
    this.titleDocumentsModal = this.$t('label.blDocuments')+': '+title;
  }

  async function Update() {
    this.$store.state.yardManagement.loading = true;
    this.isEdit = false;
    await this.getYardCargo();
    await this.getBLList();
  }

  function closeModal(){
    this.ModalBlContainer = false;
    this.ModalBlDocument = false;
    this.documentList = [];
    this.ContainerItem = {};
    this.titleModal = '';
    this.titleDocumentsModal = '';
    this.isEdit = false;
  }

  //computed
  function formatedItems() {
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    if (this.items.length > 0) {
      return this.items.map((item,) => {
        return {
          ...item,
          Nro: item.IdX,
          BookingNro: item.BookingNro ? item.BookingNro : 'N/A',
          CustomOperation:  item[`CustomOperationDs${_lang}`] ? item[`CustomOperationDs${_lang}`] : 'N/A',
          CustomBrokerName: item.CustomBrokerName ?? 'N/A',
          ConsigneeName: item.ConsigneeName ?? 'N/A',
          Dua: item.Dua ?  item.Dua : 'N/A',
          Procedence: item.Procedence ? item.Procedence : 'N/A',
          ReceptionBpAct: item.ReceptionBpAct ? item.ReceptionBpAct : 'N/A',
          Quantity: formatMilDecimal(item?.Quantity?.toFixed(2)) ?? '0,00',
          Weigth: formatMilDecimal(item?.Weigth?.toFixed(2)) ?? '0,00',
          Volumen: formatMilDecimal(item?.Volumen?.toFixed(2)) ?? '0,00',
          DescriptionOfGoods: trimRemoveSpecialCharacters(removeTags(item.DescriptionOfGoods)) ?? 'N/A',
          DescriptionOfGoodsShowEdit: item.DescriptionOfGoods,
          TransaLogin: item.TransaLogin ?? 'N/A',
          FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate): 'N/A',
          _classes: item.Status != 'ACTIVO' ? 'table-danger' : 'color-gradient',
          _style: item.Status == 'ACTIVO' ? `background: ${item?.YardCargoBlStatusColor}` : '',
        };
      });
    }
  }

  function fields(){
    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    return [
      { key: "Detalle", label: "",_style:'width:10%;min-width:135px;', filter: false,},
      { key: 'Nro', label: '#',_style: 'min-width: 60px;', filter: false, _classes: 'text-uppercase text-center'},
      { key: 'BlNro',label: this.$t('label.bl'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center'},
      { key: 'TpBlName', label: this.$t('label.type'),  _classes: 'text-uppercase text-center', _style: 'min-width:160px; text-align:center;' },
      { key: 'ConsigneeName', label: this.$t('label.consignee'), _style:'min-width:150px;', _classes: 'text-uppercase text-center' },
      { key: 'CustomBrokerName', label: this.$t('label.customBroker'),  _style:'min-width:170px; text-align:center;', _classes:"text-center"  },
      { key: 'CustomOperation', label: this.$t('label.customsRegime'),  _style:'min-width:220px; text-align:center;', _classes:"text-center"  },
      { key: 'BookingNro', label: this.$t('label.nBooking'),  _classes: 'text-uppercase text-center', _style: 'min-width:180px; text-align:center;' },
      { key: 'DescriptionOfGoods', label: this.$t('label.inventoryContains'), _style:'min-width:220px; text-align:center;', _classes:"text-center" },
      { key: 'Dua', label: this.$t('label.nDua'), _style:'min-width:140px; text-align:center;', _classes:"text-center" },
      { key: 'ReceptionBpAct', label: this.$t('label.bolipuertoReceptionRecordNro'), _style:'min-width:300px; text-align:center;', _classes:"text-center" },
      { key: 'Procedence', label: this.$t('label.Origin'),  _style:'min-width:150px; text-align:center;', _classes:"text-center"  },
      { key: 'Weigth', label: this.$t('label.weight')+' '+('(KGM)'), _style:'min-width: 140px;', _classes: 'text-uppercase text-center'},
      { key: 'Volumen', label: this.$t('label.volume')+' '+('(M³)'), _style:'min-width: 140px;', _classes: 'text-uppercase text-center' },
      { key: 'Quantity', label: this.$t('label.quantity'), _style:'min-width: 120px;', _classes: 'text-uppercase text-center' },
      { key: `BlStatus${_lang}`, label: this.$t('label.statusCertificate'), _style:'min-width:200px;', _classes:"text-center text-bold" },
      { key: `YardCargoBlStatus${_lang}`, label: this.$t('label.statusYardWarehouse'), _style:'min-width:260px;', _classes:"text-center text-bold" },
      { key: 'TransaLogin', label: this.$t('label.user'), _style:'min-width: 180px;', _classes:"text-center"},
      { key: 'FormatedDate', label: this.$t('label.date'), _style:'min-width: 180px;', _classes:"text-center"},
      { key: 'Status', label: this.$t('label.status'),  _style:'min-width: 180px; text-align:center;', _classes:"text-center" },
    ];
  }

  function ValidateTpCargoStatus() {
    return process.env.VUE_APP_TP_CARGO_STATUS_EMPTY == this.dataContainer?.TpCargoStatusId?.toUpperCase();
  }

  function Tab() {
    return this.tabIndex == 0 && this.yardCollapse == 2 && !this.isSelect;
  }
  
  export default {
    name: 'bl-index',
    components: {
      ModalBlContainer,
      ModalBlDocument
     },
    data,
    mixins: [GeneralMixin],
    methods: {
      getBLList,
      getYardCargo,
      getBl,
      toggleContainerAdd,
      toggleContainerEdit,
      viewDocument,
      Update,
      closeModal,
    },
    computed: {
      fields,
      formatedItems,
      ValidateTpCargoStatus,
      Tab,
      ...mapState({
        CargoId: state => state.yardManagement.dataContainer.CargoId,
        YardCargoId: state => state.yardManagement.dataContainer.YardCargoId,
        YardId: state => state.yardManagement.yardData.YardId,
        dataContainer: state => state.yardManagement.dataContainer,
        GeneralList: state => state.yardManagement.GeneralList,
        yardCollapse: state => state.yardManagement.yardCollapse,
        isSelect: state => state.yardManagement.isSelect,
        tabIndex: state => state.yardManagement.ContainerTabIndex,
        FgPositioning: state => state.yardManagement.FgPositioning,
      })
    },
    watch: {
      Tab: function (val){
        if(val){
          this.$store.state.yardManagement.ContainerTabIndex = 0;
          this.$store.state.yardManagement.dropContainerMovement = 0;
          this.$store.state.yardManagement.dropItemInspection = 0;
          this.items = [];
          this.getBLList();
        }
      },
    }
  };
  </script>
  <style scoped>
  .Containers-header{
    background: #4e5a70;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
  }
  .Containers-Search{
    background: #ffffff;
    border-radius: 10px;
  }
  .form-control-Search{
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border: 1px solid;
    color: #768192;
    background-color: #fff;
    border-color: #fff;
    border-radius: 10px;
    outline: none;
  }
  .Containers-data{
    margin: 5px;
    padding: 5px 15px;
    background-color: #fff;
    border-radius: 10px;
  }
  
  .Container-items{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
  }
  .value-Table{
    background-color: white;
  }
  </style>