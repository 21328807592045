<template>
  <div class="yard-body">
    <div class="wrapper d-flex align-items-stretch">
      <nav id="sidebar-Detail-Containers">
          <div class="list-menu">
            <CRow>
                <CCol sm="12">
                  <img :src="imageSrc(this.dataContainer?.TpCargoRoute)"  width="50px" height="50px" class="img bg-wrap text-center" @error="'/img/errorimage.jpg'"/>
                </CCol>
            </CRow>
          </div>
          <div class="p-3">
            <ul class="list-unstyled components mb-5">
              <li>
                <div class="list-menu">
                  <CRow>
                      <CCol sm="12">
                          <h5 class="border-title d-flex align-items-center justify-content-center py-0">
                            <b><label class="mb-0">{{ `${$t('label.serialNumber')}:  ${GetData(dataContainer?.ContainerCode)}`}}</label></b>                                                   
                          </h5>
                          <h5 class="border-title d-flex align-items-center justify-content-center py-0">
                            <b class="mr-2"><label class="mb-0">{{ `${$t('label.activity')}: ${GetData(this?.$i18n?.locale=='en' ? dataContainer?.PortActivityNameEn : dataContainer?.PortActivityNameEs) }  `}}</label></b> 
                            <CIcon size='lg' name="checkListCircleGreen"/>
                          </h5>
                      </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{`${$t('label.vessel')} / ${$t('label.voyage')}`}}  
                    </CCol>
                    <CCol sm="6" style="font-size: 12px;" class="pl-0">
                      {{ dataContainer.VesselName ? `${GetData(dataContainer.VesselName)} - ${GetData(dataContainer.VoyageArrival)}` : ''}}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{`${GetData(dataContainer?.LabelArrival)} / ${GetData(dataContainer?.LabelDeparture)}`}}
                    </CCol>
                    <CCol sm="6" style="font-size: 12px;" class="pl-0">
                      {{ `${GetDate(dataContainer?.Arrival)} - ${GetDate(dataContainer?.Departure)}` }}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.Origin')}}  
                    </CCol>
                    <CCol sm="6" style="font-size: 12px;" class="pl-0">
                      {{ GetData(dataContainer?.Procedence) }}
                    </CCol>
                  </CRow>
                </div>
              </li> 
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.yard')}}  
                    </CCol>
                    <CCol sm="6" style="font-size: 12px;" class="pl-0">
                      {{ GetData(dataContainer?.YardName) }}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.receptionDate')}}
                    </CCol>
                    <CCol sm="6" style="font-size: 12px;" class="pl-0 d-flex align-items-center">
                      {{ ValidateTpEirTransac ? GetDate(dataContainer?.ReceptionDateOrigin) :  GetDate(dataContainer?.ReceptionDate) }}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.dispatch')}}
                    </CCol>
                    <CCol sm="6" style="font-size: 12px;" class="pl-0">
                      {{ GetDate(dataContainer?.DispatchDate) }}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.daysInYard')}}
                    </CCol>
                    <CCol sm="6" style="font-size: 12px;" class="pl-0">
                      {{ GetData($i18n.locale=='en' ? dataContainer?.DaysInYardEn : dataContainer?.DaysInYardEs) }}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{`${$t('label.Seal')} 1`}}
                    </CCol>
                    <CCol sm="6" style="font-size: 12px;" class="pl-0">
                      {{ GetData(dataContainer?.Seal1) }}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{`${$t('label.Seal')} 2`}}
                    </CCol>
                    <CCol sm="6" style="font-size: 12px;" class="pl-0">
                      {{ GetData(dataContainer?.Seal2) }}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{`${$t('label.Seal')} 3`}}
                    </CCol>
                    <CCol sm="6" style="font-size: 12px;" class="pl-0">
                      {{ GetData(dataContainer?.Seal3) }}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{`${$t('label.Seal')} 4`}}
                    </CCol>
                    <CCol sm="6" style="font-size: 12px;" class="pl-0">
                      {{ GetData(dataContainer?.Seal4) }}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.LastMovement')}}
                    </CCol>
                    <CCol sm="6" style="font-size: 12px;" class="pl-0 d-flex align-items-center">
                      {{dataContainer?.LastMovementJson && GetData(this?.$i18n?.locale=='en' ? dataContainer?.LastMovementJson[0]?.EventNameEn : dataContainer?.LastMovementJson[0]?.EventNameEs) }}
                    </CCol>
                  </CRow>
                </div>
              </li>
              <li>
                <div class="list-menu">
                  <CRow>
                    <CCol sm="6" class="d-flex align-items-center" style="font-size: 12px;">
                      {{$t('label.lastMovementDate')}}
                    </CCol>
                    <CCol sm="6" style="font-size: 12px;" class="pl-0 d-flex align-items-center">
                      {{ GetDate(dataContainer?.LastMovementJson && dataContainer?.LastMovementJson[0]?.TransactionDate ) }}
                    </CCol>
                  </CRow>
                </div>
              </li>
            </ul>
          </div>
      </nav>
            
      <!-- Page Content  -->
        
      <div id="content">
        <nav
          class="navbar navbar-expand-lg navbar-light bg-light-blue"
          :class="{'no-margin': null}"
        >
          <div class="container-fluid">
            <button type="button"  id="CollapseSidebar" class="btn bg-none text-withe" @click="checkSideBarToggle()">
              <CIcon  class="text-white" :name="checkIconToggle"/>
              <span class="sr-only">{{checkTextToggle}}</span>
            </button>
            <div class="collapse navbar-collapse">
              <ul class="nav navbar-nav ml-auto w-100 justify-content-start">

                <li class="border-title d-flex align-items-center justify-content-center mx-1 py-0 my-1" :style="'background-color:'+this.dataContainer?.YardCargoStatusColor">
                  <CIcon size='lg' :name="'movementYardDetaild'" class="mr-1"/>
                  <label class="mb-0">{{`${GetData(this?.$i18n?.locale=='en' ? dataContainer?.YardCargoStatusDsEn : dataContainer?.YardCargoStatusDsEs)}`}}</label>
                </li>

                <li class="border-title align-items-center justify-content-center mx-1 my-1" >
                  <label >{{` ${$t('label.condition')} : ${GetData(dataContainer?.TpCargoConditionCode)}`}}</label>
                </li>

                <li class="border-title align-items-center justify-content-center mx-1 my-1" >
                  <label >{{` ${$t('label.status')} : ${GetData(dataContainer?.TpCargoStatusName)}`}}</label>
                </li>

                <li class="border-title d-flex align-items-center justify-content-center mx-1 py-0 my-1" >
                  <CIcon size='xl' name="containerSizeIcon" class="mr-1"/>
                  <label class="mb-0">{{` ${$t('label.type')} : ${dataContainer?.TpCargoDetailCode}`}}</label>
                </li>
               
                <li class="border-title align-items-center justify-content-center mx-1 my-1" v-if="dataContainer?.DimFront !=null || dataContainer?.DimTop !=null || dataContainer?.DimBack !=null || dataContainer?.DimLeft !=null || dataContainer?.DimRight !=null ">
                  <label >{{` ${$t('OVERSIZE')} : 
                  ${dataContainer?.DimFront !=null ? $t('label.DimFrontMov')+"="+dataContainer?.DimFront+',' :''}
                  ${dataContainer?.DimTop !=null ? $t('label.DimTopMov')+"="+dataContainer?.DimTop+',' :''}
                  ${dataContainer?.DimBack !=null ? $t('label.DimBackMov')+"="+dataContainer?.DimBack+',' :''}
                  ${dataContainer?.DimLeft !=null ? $t('label.DimLeftMov')+"="+dataContainer?.DimLeft+',' :''}
                  ${dataContainer?.DimRight !=null ? $t('label.DimRightMov')+"="+dataContainer?.DimRight :''}`

                  }}</label> 
                </li>

                <li class="border-title d-flex align-items-center justify-content-center mx-1 py-0 my-1 max-width:80%;">
                  <img src="/img/iconos/Temperatura-Icon.svg" width="auto" height="80%" class="mr-1">
                  <label class="mb-0">{{` ${$t('TEMP')} : ${dataContainer?.ReeferSetting? $t('label.yes'): $t('label.no')}`}}</label> 
                </li> 

                <li class="border-title d-flex align-items-center justify-content-center mx-1 my-1 max-width:80%;">
                  <img src="/img/iconos/Plug-In-Icon.svg" width="auto" height="100%" class="mr-1">
                  <label class="mb-0">{{` ${$t('label.plugged')} : ${dataContainer?.Plugged? $t('label.yes'): $t('label.no')}`}}</label> 
                </li>

                <li :style="`background:${dataContainer?.InTpTransacEirColor ?? '#3399ff'}`" class="border-title d-flex align-items-center justify-content-center mx-1 py-0 my-1" v-if="dataContainer?.InTpEirTransacId">
                  <CIcon name="ReceptionComodity" class="mr-1"/>
                  <label class="m-0">{{`${$t('label.TransacAcronym')} : ${GetData(dataContainer?.InTpTransacEirAcronym)}`}}</label>
                </li>

                <li :style="`background:${dataContainer?.OutTpTransacEirColor ?? '#3399ff'}`" class="border-title d-flex align-items-center justify-content-center mx-1 py-0 my-1" v-if="dataContainer?.OutTpEirTransacId">
                  <CIcon name="DispatchComodity" class="mr-1"/>
                  <label class="m-0">{{`${$t('label.TransacAcronym')} : ${GetData(dataContainer?.OutTpTransacEirAcronym)}`}}</label>
                </li>

                <li v-if="BlJson?.BlCargoId" class="border-title d-flex align-items-center justify-content-center mx-1 py-0 my-1">
                  <label class="m-0">{{`${$t('label.Acta')} :`}}</label>
                  <div class="bg-round mx-1" :style="`background:${BlJson?.BlStatusColor}`"></div>
                  <label class="m-0">{{`${GetData($i18n.locale=='en' ? BlJson?.BlStatusEn : BlJson?.BlStatusEs)} ${BlJson?.DocumentCode ? `- ${BlJson?.DocumentCode}` : ''}`}}</label>
                </li>

                <li v-if="BlJson?.BlCargoId" class="border-title d-flex align-items-center justify-content-center mx-1 py-0 my-1">
                  <label class="m-0">{{`${$t('label.bl')} :`}}</label>
                  <div class="bg-round mx-1" :style="`background:${BlJson?.YardCargoBlStatusColor}`"></div>
                  <label class="m-0">{{`${GetData($i18n.locale=='en' ? BlJson?.YardCargoBlStatusEn : BlJson?.YardCargoBlStatusEs)}`}}</label>
                </li>

                <li class=" d-flex align-items-center justify-content-center  mx-1 " v-if="dataContainer?.AlertsJson?.length>0">
                  <CButton
                    shape="square"
                    class="d-flex align-items-center ml-auto"
                    style="max-height: 80%; background-color: #e7ac2c;"
                    @click="openModalAlerts()"
                  >
                    <CIcon name="cil-warning"  class="text-white"/><span class="ml-1 text-white" style="font-size: 12px;">{{$t('label.alerts')}}</span>
                  </CButton>
                </li>
              </ul>
            </div>
            <button type="button"  class="btn bg-none text-white" @click="closeCollapse()">
              <CIcon name="x"/>
            </button>
          </div>
        </nav>
          <!-- Contenido Interno -->
        <div class="container-fluid contenido-especial pt-3" >
            <div class="border-title align-items-stretch mx-1 mb-3 mt-2" style="background-color: white; color: #828c9c; height: auto;">

              <CRow class="align-items-start" v-if="dataContainer?.BlJson?.length==1">
                <CCol sm="12" xl="4">

                  <CRow>
                    <CCol sm="6" lg="5" class="d-flex justify-content-end px-1">
                      <b><label class="text-right p-0">{{$t('label.bl')}}</label></b>
                    </CCol>
                    <CCol sm="6" lg="7" class="pb-0 mb-0 px-1">
                      <label class="text-left">{{GetData(BlJson?.BlNro)}}</label> 
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol sm="6" lg="5" class="d-flex justify-content-end px-1">
                      <b><label class="text-right p-0">{{$t('label.consignee')}}</label></b>
                    </CCol>
                    <CCol sm="6" lg="7" class="pb-0 mb-0 px-1">
                      <label class="text-left">{{GetData(BlJson?.ConsignneName)}}</label>
                    </CCol>
                  </CRow>
                </CCol>

                <CCol sm="12" xl="4">
                  <CRow>
                    <CCol sm="6" lg="5" class="d-flex justify-content-end px-1">
                      <b><label class="text-right p-0">{{$t('label.customsRegime')}}</label></b>
                    </CCol>
                    <CCol sm="6" lg="7"  class="pb-0 mb-0 px-1">
                      <label class="text-left">{{GetData($i18n?.locale=='en' ? BlJson?.CustomOperationDsEn : BlJson?.CustomOperationDsEs)}}</label>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol sm="6" lg="5" class="d-flex justify-content-end px-1">
                      <b><label class="text-right p-0">{{$t('label.BolipuertoCertificateEntry')}}</label></b>
                    </CCol>
                    <CCol sm="6" lg="7" class="pb-0 mb-0 px-1">
                      <label class=" text-left">{{GetData(BlJson?.ReceptionBpAct)}}</label>
                    </CCol>
                  </CRow>
                </CCol>

                <CCol sm="12" xl="4" class="pb-0">
                  <CRow>
                    <CCol sm="6" lg="5" class="d-flex justify-content-end px-1">
                      <b><label class="text-right p-0">{{$t('label.customBroker')}}</label></b>
                    </CCol>
                    <CCol sm="6" lg="7" class="pb-0 mb-0 px-1">
                      <label class=" text-left">{{GetData(BlJson?.CustomBrokerName)}}</label>
                    </CCol>
                  </CRow>

                  <CRow>
                    <CCol sm="6" lg="5" class="d-flex justify-content-end px-1">
                      <b><label class="text-right p-0">{{$t('label.inventoryContains')}}</label></b>
                    </CCol>
                    <CCol sm="6" lg="7" class="pb-0 mb-0 px-1">
                      <label class="text-left">{{GetDescriptionOfGoods(BlJson?.DescriptionOfGoods)}}</label>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>

              <CRow class="align-items-start mt-0">
                <CCol sm="12" xl="8">
                  <CRow>
                    <CCol sm="6" lg="2" class="d-flex justify-content-end px-1">
                      <b><label class="text-right p-0">{{$t('label.positionContainer')}}</label></b>
                    </CCol>
                    <CCol sm="5" lg="auto" class="row align-items-center px-1 m-0">
                      <div  v-for="(item, index) in GetPositions(this.dataContainer?.CurrentPositionJson, true)" class="row col-sm-auto mb-1">
                        <b><label class="text-left mb-0 text-center">{{item.SlotCode}}</label></b>
                        <label  class="text-left mb-0 mr-2">{{  `(${this?.$i18n?.locale=='en' ? item.YardSlotStatusNameEn : item.YardSlotStatusNameEs})`}}</label>
                      </div>
                    </CCol>
                    <CCol v-if="GetPositions(this.dataContainer?.CurrentPositionJson)?.length>0" sm="1" lg="auto" class="row d-flex align-items-center">
                      <div>
                        <CButton
                          shape="square"
                          color="add"
                          square
                          size="sm"
                          class="ml-1 p-0"
                          @click="viewDetail()"
                        >
                          <CIcon name="cil-playlist-add"/>
                        </CButton>
                      </div>
                    </CCol>
                  </CRow>
                  <CCollapse
                    :show="Boolean(toggled)"
                    :duration="collapseDuration"
                    class="mt-2"
                  >
                    <CCardBody class="pt-0 px-0">
                      <CRow>
                        <CCol sm="6" lg="2"></CCol>
                        <CCol sm="6" lg="8" class="row pl-5">
                          <div v-for="(item, index) in GetPositions(this.dataContainer?.CurrentPositionJson)" class="row col-sm-12 col-lg-5 mb-1">
                            <b><label class="text-left mb-0">{{item.SlotCode}}</label></b>
                            <label  class="text-left mb-0 mr-2">{{  `(${this?.$i18n?.locale=='en' ? item.YardSlotStatusNameEn : item.YardSlotStatusNameEs})`}}</label>
                          </div>
                        </CCol>
                      </CRow>
                    </CCardBody>
                  </CCollapse>
                </CCol>
                <CCol sm="12" xl="4" v-if="dataContainer?.BlJson?.length==1">
                  <CRow>
                    <CCol sm="6" lg="5" class="d-flex justify-content-end">
                      <b><label class="text-right p-0">{{$t('label.confirmationDateAbrev')}}</label></b>
                    </CCol>
                    <CCol sm="6" lg="7" class="pb-0 mb-0">
                      <label class="text-left">{{ConfirmationDate}}</label>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
              
            </div>
            <CRow>
              <CCol sm="12">
                <CustomTabs :active-tab="$store.state.yardManagement.ContainerTabIndex" @update:activeTab="handleTab">
                  <CustomTab
                    v-show="!ValidateTpCargoStatus"
                    :title="$t('label.bl')"
                    valueItemTab="0"
                    icon="conditions"
                  >
                    <Bl></Bl>
                  </CustomTab>
                  <CustomTab
                    :title="$t('label.generalInfo')"
                    valueItemTab="1" 
                    icon="general"                                                  
                  >
                    <GeneralInfo></GeneralInfo>
                  </CustomTab>
                  <CustomTab 
                    valueItemTab="2"
                  >
                    <template #title>
                      <CIcon name="view" class="mr-1"/>
                      <CDropdown
                        :togglerText="$t('label.movements')"
                        class="pl-0"
                        :flip="false"
                      >
                        <CDropdownItem :active="activeItem(1)" @click="clickItem(1)">{{$t('label.reception')}}</CDropdownItem>
                        <CDropdownItem :active="activeItem(3)" @click="clickItem(3)">{{$t('label.containerPositioning')}}</CDropdownItem>
                        <CDropdownItem :active="activeItem(2)" @click="clickItem(2)">{{$t('label.dispatch')}}</CDropdownItem>
                      </CDropdown>
                    </template>
                    <Movements></Movements>
                  </CustomTab>
                  <CustomTab 
                      valueItemTab="3"
                  >
                    <template #title>
                      <CIcon name="Inspection" class="mr-1"/>
                      <CDropdown
                          :togglerText="$t('label.inspections')"
                          class="pl-0"
                          :flip="false"
                      >
                        <CDropdownItem :active="activeInspectionItem(1)" @click="clickInspectionItem(1)">{{$t('label.damageOption')}}</CDropdownItem>
                        <CDropdownItem :active="activeInspectionItem(2)" @click="clickInspectionItem(2)">{{$t('label.Seals')}}</CDropdownItem>
                        <CDropdownItem v-if="!ValidateTpCargoStatus" :active="activeInspectionItem(3)" @click="clickInspectionItem(3)">{{ `${$t('label.recognition')}`}}</CDropdownItem>
                      </CDropdown>
                    </template>
                    <Inspections></Inspections>
                  </CustomTab>
                  <CustomTab  
                    :style="checkTabs"
                    valueItemTab="4"
                    v-if="isCoverWareHouse"
                  >
                    <template #title>
                      <CDropdown
                          :togglerText="$t('label.events')"
                          class="text-white pl-0"
                          :flip="false"
                      >
                        <CDropdownItem :active="eventActiveItem(1)" @click="clickEventItem(1)">{{$t('label.deconsolidated')}}</CDropdownItem>
                        <!--<CDropdownItem :active="eventActiveItem(2)" @click="clickEventItem(2)">{{$t('label.emptying')}}</CDropdownItem>-->
                        <CDropdownItem :active="eventActiveItem(3)" @click="clickEventItem(3)">{{$t('label.blsPendingReceive')}}</CDropdownItem>
                        <!--
                          <CDropdownItem :active="eventActiveItem(1)" @click="clickEventItem(1)">{{$t('VACIADO')}}</CDropdownItem>
                          <CDropdownItem :active="eventActiveItem(2)" @click="clickEventItem(2)">{{$t('USO DE IMPLEMENTOS')}}</CDropdownItem>
                          <CDropdownItem :active="eventActiveItem(3)" @click="clickEventItem(3)">{{$t('TRASEGADO')}}</CDropdownItem>
                          <CDropdownItem :active="eventActiveItem(4)" @click="clickEventItem(4)">{{$t('ACARREO')}}</CDropdownItem>
                          <CDropdownItem :active="eventActiveItem(5)" @click="clickEventItem(5)">{{$t('TRASEGADO')}}</CDropdownItem>
                          <CDropdownItem> 
                            <nav id="menu_gral">
                              <ul>
                                <li class="dropdown_gral">
                                  <a href="javascript:void(0)">{{$t('EMISIÓN DE DOCUMENTOS')}}</a>
                                  <ul>
                                    <li><a href="javascript:void(0)" :active="eventActiveItem(6)" @click="clickEventItem(6)">{{$t('label.quantity')}} </a></li>
                                    <li><a href="javascript:void(0)" :active="eventActiveItem(7)" @click="clickEventItem(7)">{{$t('HABILITACIÓN')}}</a></li>
                                  </ul>
                                </li>
                              </ul>
                            </nav>
                          </CDropdownItem>-->
                        </CDropdown>
                    </template>
                    <Events></Events>
                  </CustomTab>
                  <CustomTab
                    :style="checkTabs"
                    valueItemTab="5"
                  >
                    <template #title>
                      <CIcon name="Schedule" class="mr-1"/>
                      {{ $t('label.transactions') }}
                    </template>
                    <Transactions></Transactions>
                  </CustomTab>
                  <CustomTab
                      :style="checkTabs"
                      :title="$t('label.documentation')"
                        valueItemTab="6"
                  >
                    <Documentations></Documentations>
                  </CustomTab>
                  <CustomTab
                      :style="checkTabs"
                      :title="$t('label.eir')"
                       valueItemTab= "7"
                  >
                    <Eir ></Eir>
                  </CustomTab>
                </CustomTabs>
              </CCol>
            </CRow>
        </div>
      </div>
    </div>
    <br><br>
    <AlertsModal
      :modal.sync="alertModal"
      @close="closeModal"
    /> 
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import { DateFormater, trimRemoveSpecialCharacters } from '@/_helpers/funciones';
  import GeneralMixin from '@/_mixins/general';
  import CustomTabs from '@/components/tabs/CustomTabs';
  import CustomTab from '@/components/tabs/CustomTab';
  import Bl from './bl/bl-index';
  import GeneralInfo from './general-info/general-index-info';
  import Movements from './movements/movements-index';
  import Inspections from './inspection/inspections-index';
  import Events from './events/events-index';
  import Transactions from './transactions/transactions-index';
  import Documentations from './documentations/documentations-index';
  import Eir from './eir/eir-index';
  import AlertsModal from './alerta-modal';
  

  //DATA-------------------------
  function data() {
    return {
      alertModal: false,
      collapsed: false,
      collapseDuration: 0,
      toggled: false
    }
  }

  //mounted
  function mounted(){
        document.getElementById("CollapseSidebar").onclick = function() {
            document.getElementById("sidebar-Detail-Containers").classList.toggle('active');
        }
    }

  //methods
  async function getBlCargoById() {
    this.$store.state.yardManagement.loading = true;
    await this.$http.get("BlCargo-by-Id", { BlCargoId: this.BlCargoId})
    .then(response => {
      let Bl = response?.data?.data?.[0] ?? undefined;
      if (Bl) {
        this.$store.state.yardManagement.dataBl = Bl;
        this.$store.state.yardManagement.yardCollapse = 4;
      }else{
        this.$store.state.yardManagement.yardCollapse = 1;
      }
    }).catch( err => {
        this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
  }

  function checkSideBarToggle(){
    this.collapsed = !this.collapsed;
  }

  function checkTabs() {
    //return this.ConditionPlanningId=='52FE865B-6267-4EF4-833D-4C7B20CD2F35' ? 'display: none;' : 'text-transform: uppercase;';
    return 'text-transform: uppercase;';
  }

  function checkIconToggle() {
      if (this.collapsed) {
          return "cil-chevron-right-alt";
      } else {
          return "cil-chevron-left-alt";
      }
  }
    
  function checkTextToggle() {
      if (this.collapsed) {
          return this.$t("label.close");
      } else {
          return this.$t("label.watch");
      }
  }

  function handleTab(tab) {
    this.$store.state.yardManagement.dropContainerMovement = 0;
    this.$store.state.yardManagement.dropItemInspection = 0;
    this.$store.state.yardManagement.dropItemEvent  = 0;
    this.$store.state.yardManagement.ContainerTabIndex = tab;
  }

  async function closeCollapse(){
    if (this.FgContainerNavigation) {
      this.$store.state.yardManagement.FgContainerNavigation = false;
      if (this.BlCargoId) {
        await this.getBlCargoById();
      }else{
        this.$store.state.yardManagement.yardCollapse = 1;
      }
    }else {
      this.$store.state.yardManagement.yardCollapse = 1;
    }
    this.$store.state.yardManagement.BlCollapse = 0;
    this.$store.state.yardManagement.dataContainer = {};
    this.$store.state.yardManagement.dropContainerMovement = 0;
    this.$store.state.yardManagement.dropItemInspection = 0;
    this.$store.state.yardManagement.dropItemEvent  = 0;
    this.$store.state.yardManagement.ContainerTabIndex = 0;
  }

  function GetData(Data) {
    return Data ? Data : '';
  }
  function GetDate(date) {
    if (date) {
      return DateFormater.formatDateTimeWithSlash(date);
    } else {
      return 'N/A';
    }
  }

  function openModalAlerts(){
    this.alertModal = true;
  }

  function closeModal(){
    this.alertModal = false;
  }

  function viewDetail(){
    this.toggled = !this.toggled;
    this.collapseDuration = 300;
  }

  function GetPositions(data, current = false) {
    if(data){
      if (current) {
        return data.filter((item, i) => {return process.env.VUE_APP_YARD_SLOT_STATUS==item.YardSlotStatus?.toUpperCase()});
      } else {
        return data.filter((item, i) => {return process.env.VUE_APP_YARD_SLOT_STATUS!=item.YardSlotStatus?.toUpperCase()});
      }
    }else{
      return [];
    }  
  }

  function GetDescriptionOfGoods(value) {
    return trimRemoveSpecialCharacters(value);
  }

  function imageSrc(Src){
    return Src ? `${this.$store.getters["connection/getBase"]}${Src}` : '/img/errorimage.jpg';
  }
  
  //computed
  function isCoverWareHouse() {
    return this.dataContainer?.TpYardId == process.env.VUE_APP_YARD_TP_ID_COVER_WAREHOUSE
  }

  function VisitOptions(){
      return [
        { label: 'DECK TO', value: '1', option: 'DECK TO' },
        { label: 'UNDER DECK', value: '2', option: 'UNDER DECK'  },
        { label: 'ALL', value: '3', option: 'ALL'  },
      ];
  }

  function BlJson() {
    return this.dataContainer?.BlJson?.find(e => e.TpBlId == process.env.VUE_APP_TP_BL_ID_MASTER) ?? {};
  }

  function ConfirmationDate() {
    return (this.dataContainer && this.dataContainer?.ConfirmationDate) ?  DateFormater.formatDateTimeWithSlash(this.dataContainer?.ConfirmationDate) : '';
  }

  function containerSearchActivity() {
    this.$store.state.yardManagement.yardCollapse = 2;
  }

  function activeItem(item) {
      return this.dropContainerMovement === item;
  }

  function activeInspectionItem(item) {
      return this.dropItemInspection === item;
  }

  function eventActiveItem(item) {
      return this.dropItemEvent === item;
  }

  function clickItem(item){
      this.$store.state.yardManagement.dropContainerMovement = item;
  }

  function clickInspectionItem(item){
      this.$store.state.yardManagement.dropItemInspection  = item;
  }

  function clickEventItem(item){
    this.$store.state.yardManagement.dropItemEvent  = item;
  }

  function ValidateTpCargoStatus() {
    return process.env.VUE_APP_TP_CARGO_STATUS_EMPTY == this.dataContainer?.TpCargoStatusId?.toUpperCase();
  }

  function ValidateTpEirTransac() {
    return process.env.VUE_APP_TP_EIR_TRANSAC_EMPTY_RETURN == this.dataContainer?.TpEirTransacId?.toUpperCase();
  }

  export default{
      name: 'movements-index',
      data,
      mixins: [GeneralMixin],
      props: {},
      components: {
        CustomTabs,
        CustomTab,
        Movements,
        Events,
        Inspections,
        Bl,
        GeneralInfo,
        AlertsModal,
        Transactions,
        Documentations,
        Eir
      },
      mixins: [GeneralMixin],
      methods: {
        getBlCargoById,
        closeCollapse,
        handleTab,
        checkSideBarToggle,
        containerSearchActivity,
        activeItem,
        activeInspectionItem,
        eventActiveItem,
        clickItem,
        clickInspectionItem,
        clickEventItem,
        GetData,
        GetDate,
        GetPositions,
        viewDetail,
        imageSrc,
        openModalAlerts,
        closeModal,
        GetDescriptionOfGoods
      },
      mounted:mounted,
      computed:{
          isCoverWareHouse,
          checkTabs,
          checkTextToggle,
          checkIconToggle,
          VisitOptions,
          BlJson,
          ConfirmationDate,
          ValidateTpCargoStatus,
          ValidateTpEirTransac,
          ...mapState({
              yardData: state => state.yardManagement.yardData,
              dropContainerMovement: state => state.yardManagement.dropContainerMovement,
              dropItemInspection: state => state.yardManagement.dropItemInspection,
              dropItemEvent: state => state.yardManagement.dropItemEvent,
              dataContainer: state => state.yardManagement.dataContainer,
              FgContainerNavigation: state => state.yardManagement.FgContainerNavigation,
              FgBlNavigation: state => state.yardManagement.FgBlNavigation,
              BlCargoId: state => state.yardManagement.dataBl.BlCargoId,  
          })
      },
  }
</script>
<style lang="scss">
    @import '../css/style.scss';

    .navWidth{
      width: 15%;
    }
    .border-title{
      background-color: #1a3760;
      color: white;
      display: block;
      height: calc(1.5em + 0.75rem + 1px);
      padding: 0.375rem 0.75rem;
      font-size: 11px;
      font-weight: 400;
      line-height: 1.5;
      background-clip: padding-box;
      border: 1px solid;
      border-color: #d8dbe0;
      border-radius: 0.50rem;
      -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    }
    .custom-nav li > .custom-tab-link{
      .dropdown{
        width:100%;
        padding: 0 20px;
        .dropdown-toggle {
          color:white;
          opacity: 1;
          padding-left: 0;
          padding-top: 0;
        }
        .dropdown-toggle::after {
          margin-left: 0.255em;
          margin-right: 1.0em;
        }
      }
    }
    .custom-nav li.active > .custom-tab-link {
      .dropdown{
          width:100%;
          padding: 0 20px;
        .dropdown-toggle {
          color: #000;
          opacity: 1;
        }
        .dropdown-toggle::after {
          margin-left: 0.255em;
          margin-right: 1.0em;
        }
      }
    }
</style>
<style scoped>
.bg-round {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
</style>