var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"align-items-center justify-content-end mx-1 my-3",staticStyle:{"background-color":"white","color":"#828c9c","height":"auto"}},[_c('CRow',{staticClass:"align-items-center justify-content-end"},[_c('CCol',{attrs:{"sm":"6","xl":"6"}})],1)],1),_c('div',[_c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"hover":"","sorter":"","small":"","details":"","column-filter":"","items":_vm.formatedItems,"fields":_vm.fields,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage,"pagination":""},scopedSlots:_vm._u([{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"cell-center text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"Detalle",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                          content: _vm.$t('label.edit')+' '+_vm.$t('label.bl'),
                          placement: 'top-start'
                          }),expression:"{\n                          content: $t('label.edit')+' '+$t('label.bl'),\n                          placement: 'top-start'\n                          }"}],staticClass:"mr-1",attrs:{"color":"edit","square":"","size":"sm"},on:{"click":function($event){return _vm.toggleContainerEdit(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                          content: _vm.$t('label.see')+' '+_vm.$t('label.document'),
                          placement: 'top-start'
                      }),expression:"{\n                          content: $t('label.see')+' '+$t('label.document'),\n                          placement: 'top-start'\n                      }"}],staticClass:"mr-1",attrs:{"square":"","size":"sm","color":"watch"},on:{"click":function($event){return _vm.viewDocument(item)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content: `${_vm.$t('label.see')} ${_vm.$t('label.bl')}`,
                        placement: 'top-start'
                      }),expression:"{\n                        content: `${$t('label.see')} ${$t('label.bl')}`,\n                        placement: 'top-start'\n                      }"}],attrs:{"square":"","size":"sm","color":"watch"},on:{"click":function($event){return _vm.getBl(item)}}},[_c('CIcon',{attrs:{"name":"cil-list"}})],1)],1)]}}])})],1)],1)],1),_c('ModalBlContainer',{attrs:{"modal":_vm.ModalBlContainer,"ContainerItem":_vm.ContainerItem,"items":_vm.items,"title":_vm.titleModal,"fgInfo":_vm.dataContainer?.FgCertified,"isEdit":_vm.isEdit},on:{"update:modal":function($event){_vm.ModalBlContainer=$event},"close":_vm.closeModal,"Update-list":_vm.Update}}),_c('ModalBlDocument',{attrs:{"modal":_vm.ModalBlDocument,"title":_vm.titleDocumentsModal,"documentList":_vm.documentList},on:{"update:modal":function($event){_vm.ModalBlDocument=$event},"close":_vm.closeModal}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }