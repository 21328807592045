<template>
  <div>
    <CRow class="justify-content-center mt-3">
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.seller')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.seller.SellerName.$model"
          :is-valid="hasError(vForm.seller.SellerName)"
          :invalid-feedback="errorMessage(vForm.seller.SellerName)"
          :disabled="fgInfo"
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.address')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.seller.SellerAddress.$model"
          :is-valid="hasError(vForm.seller.SellerAddress)"
          :invalid-feedback="errorMessage(vForm.seller.SellerAddress)"
          :disabled="fgInfo"
        >
        </CInput>
      </CCol>
    </CRow>

    <CRow class="justify-content-center">
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.mainPhone')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.seller.SellerPrimaryPhone.$model"
          :is-valid="hasError(vForm.seller.SellerPrimaryPhone)"
          :invalid-feedback="errorMessage(vForm.seller.SellerPrimaryPhone)"
          :disabled="fgInfo"
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5" >
        <CInput
          size="sm"
          :label="$t('label.secondaryPhone')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.seller.SellerSecondaryPhone.$model"
          :is-valid="hasError(vForm.seller.SellerSecondaryPhone)"
          :invalid-feedback="errorMessage(vForm.seller.SellerSecondaryPhone)"
          :disabled="fgInfo"
        >
        </CInput>
      </CCol>
    </CRow>

    <CRow class="justify-content-center">
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.mainEmail')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.seller.SellerPrimaryEmail.$model"
          :is-valid="hasError(vForm.seller.SellerPrimaryEmail)"
          :invalid-feedback="errorMessage(vForm.seller.SellerPrimaryEmail)"
          :disabled="fgInfo"
        >
        </CInput>
      </CCol>
      <CCol sm="12" lg="5">
        <CInput
          size="sm"
          :label="$t('label.secondaryEmail')"
          :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
          :placeholder="''"
          v-uppercase
          addLabelClasses="text-right"
          v-model="vForm.seller.SellerSecondaryEmail.$model"
          :is-valid="hasError(vForm.seller.SellerSecondaryEmail)"
          :invalid-feedback="errorMessage(vForm.seller.SellerSecondaryEmail)"
          :disabled="fgInfo"
        >
        </CInput>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';


//-----------------------   Method    ------------------------

async function resetForm() {
  //notify
  this.form.notify.NotifyToName ='';
  this.form.notify.NotifyToAddress = '';
  this.form.notify.NotifyToPrimaryPhone = '';
  this.form.notify.NotifyToSecondaryPhone = '';
  this.form.notify.NotifyToPrimaryEmail = '';
  this.form.notify.NotifyToSecondaryEmail = '';
}


export default {
  name: 'seller-tab',

  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    vForm: Object,
    isEdit: Boolean,
    fgInfo: Boolean,
    validCargoMaster: Boolean,
    Tab: Boolean,
    showModal: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  methods: {
    resetForm
  },
  watch: {
    showModal: async function (Newval) {
      if (!Newval) {
        this.resetForm();
      }
    },
  }
};
</script>