<template>
  <CRow class="justify-content-center m-0 mt-3">
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="computedSerializationList"
        :fields="serializationfields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
      >
        <template #Status="{ item }">
          <td class="text-center">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>
        <template #Serial="{ item }">
          <td class="text-center">
            {{ `${item.Serial} ${item?.TpCargoDetailCode ? `(${item.TpCargoDetailCode ?? ''})` : ''}`.trim() }}
          </td>
        </template>
      </dataTableExtended>
      <div class="d-flex justify-content-end text-right fw-bold">
        {{ `${$t('label.totalQuantity')}: ${FormatNumber(total.Quantity, 2)}` }} -
        {{ `${$t('label.totalWeight')}: ${FormatNumber(total.Weight, 2)}` }} -
        {{ `${$t('label.TotalVolume')}: ${FormatNumber(total.Volumen, 2)}` }}
      </div>
    </CCol>
  </CRow>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import { Money } from "v-money";
import { NumberFormater } from "@/_helpers/funciones";

function data() {
  return {
  };
}

//methods
function FormatNumber(number, decimal) {
  return NumberFormater.formatNumber(number, decimal);
}

function Clear() {
  this.form.SerializationList = [];
}

//computed
function total() {
  let Total = {
    Quantity: 0,
    Weight: 0,
    Volumen: 0,
  }

  this.form.SerializationList?.map(e => {
    Total.Quantity += e?.Quantity ?? 0;
    Total.Weight += e?.Weight ?? 0;
    Total.Volumen += e?.Volumen ?? 0;
  })

  return Total
}

function computedSerializationList() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let List = this.form.SerializationList.filter(item => item.Status != 0);
  return List.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      PackagingName: item['PackagingName'+_lang] ?? this.$t('label.BreakBulk'),
      ComputedQuantity: item.Quantity ? NumberFormater.formatNumber(item.Quantity, 2) : '0,00',
      ComputedWeight: item.Weight ? NumberFormater.formatNumber(item.Weight, 2) : '0,00',
      ComputedVolume: item.Volumen ? NumberFormater.formatNumber(item.Volumen, 2) : '0,00',
    }
  });
}

function serializationfields(){
  return [
    { key: 'Nro', label: '#', _style: 'width:3%; text-align:left', _classes: 'text-center', filter: false },
    { key: 'PackagingName', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'width:20%;', sorter: true, filter: true},
    { key: 'Serial', label: this.$t('label.serial'),  _classes: 'text-uppercase text-center', _style: 'width:20%;', sorter: true, filter: true},
    { key: 'ComputedQuantity', label: this.$t('label.quantity'), _classes: 'text-uppercase text-center', _style: 'width:15%;', sorter: true, filter: true},
    { key: 'ComputedWeight', label: `${this.$t('label.weight')} (KGM)`, _style:'width:15%;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
    { key: 'ComputedVolume', label: `${this.$t('label.volume')} (M³)`, _style:'width:15%;', _classes:"center-cell text-center", sorter: true, filter: true},
  ];
}

export default {
  name: 'serialization-tab',
  components: {
      Money
  },
  data,
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    showModal: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  methods: {
    FormatNumber,
    Clear,
  },
  computed:{
    total,
    computedSerializationList,
    serializationfields,
  },
  watch: {
    showModal: function (Newval) {
      if (!Newval) {
        this.Clear(true);
      }
    },
  }
};
</script>