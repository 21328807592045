<template>
  <div>
    <CRow class="mb-3">
      <CCol sm="12" lg="12" xl="12" class="d-flex align-items-center justify-content-start">
        <CButton
          color="edit"
          class="mr-2"
          v-c-tooltip="{
            content: $t('label.return'),
            placement: 'top',
          }"
          size="sm"
          @click="Reset()"
        >
          <CIcon name="cil-chevron-left-alt" />
        </CButton>
        <h6 class="mt-2">
          {{  `${ this.isEdit ? $t('label.editContainerReception') : $t('label.newContainerReception')}: ${this.dataContainer?.ContainerCode}`}} 
        </h6>
      </CCol>
    </CRow>
      <CRow class="justify-content-center mt-3 px-3">
        <CCol sm="12">
          <CTabs variant="tabs" :active-tab="0">
            <CTab :title="$t('label.basicData')">
              <CCard class="card-border">
                <CRow class="justify-content-center px-3">
                  <CCol sm="12" lg="6">
                    <CInput
                      v-if="this.dataContainer?.VisitCargoId || this.ValidateTpEirTransac"
                      size="sm"
                      v-uppercase
                      class="mb-3"
                      :label="$t('label.visit')"
                      :horizontal="{label:'col-sm-12 col-lg-4 text-right', input:'col-sm-12 col-lg-8'}"
                      v-model="VesselName"
                      :is-valid="hasError(true)"
                      :placeholder="$t('label.visit')"
                      :disabled="true"
                    />
                    <CSelect
                      v-else
                      size="sm"
                      :label="$t('label.itinerary')"
                      :horizontal="{label:'col-sm-12 col-lg-4 text-right required', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.select')"
                      v-model="$v.ItineraryId.$model"
                      :is-valid="hasError($v.ItineraryId)"
                      :invalid-feedback="errorMessage($v.ItineraryId)"
                      :options="ItineraryOptions"
                      :disabled="dataContainer?.FgCertified??false"
                    />
                    <CSelect
                      size="sm"
                      :label="$t('label.transaction')"
                      :horizontal="{label:'col-sm-12 col-lg-4 text-right required', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.select')"
                      v-model="$v.YardStatusSuggestTransactionId.$model"
                      :is-valid="hasError($v.YardStatusSuggestTransactionId)"
                      :invalid-feedback="errorMessage($v.YardStatusSuggestTransactionId)"
                      :options="TransactionOptions"
                      :disabled="dataContainer?.FgCertified??false"
                    />
                    <CInput
                      v-if="ValidateTpEirTransac"
                      size="sm"
                      v-uppercase
                      class="mb-3"
                      :label="$t('label.cancelationDate')"
                      :horizontal="{label:'col-sm-12 col-lg-4 text-right', input:'col-sm-12 col-lg-8'}"
                      v-model="CancellationDate"
                      :placeholder="$t('label.cancelationDate')"
                      :disabled="true"
                    />
                    <div class="form-group form-row mb-3">
                      <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right required">{{`${$t('label.receptionDate')}`}}</label>
                      <div class="input-group col-sm-12 col-lg-8 mb-0">
                        <vue-datepicker 
                          type="datetime"
                          header
                          :lang="this.$i18n.locale"
                          :editable="false"
                          :clearable="false"
                          format="DD/MM/YYYY HH:mm"
                          placeholder="DD/MM/YYYY HH:mm"
                          time-title-format="DD/MM/YYYY HH:mm"
                          v-model="$v.TransactionDate.$model"
                          :disabled-date="validateDateRange"
                          :append-to-body="false"
                          value-type="format"
                          :show-second="false"
                          class="w-100"
                          :disabled="dataContainer?.FgCertified??false"
                        >
                          <template #input>
                            <CInput
                              v-uppercase
                              class="w-100 float-left mb-0"
                              :value="TransactionDate"
                              placeholder="DD/MM/YYYY HH:mm"
                              :is-valid="hasError($v.TransactionDate)"
                              size="sm"  
                              :disabled="dataContainer?.FgCertified??false"                    
                            >
                              <template #append-content>
                                <CIcon name="cil-calendar" />
                              </template>
                            </CInput>
                          </template>
                          <template #icon-calendar>
                            <div style="display: none"></div>
                          </template>
                        </vue-datepicker>
                      </div>
                    </div>
                    <CTextarea
                      v-if="!ValidateTpEirTransac"
                      size="sm"
                      rows="2"
                      :label="$t('label.observation')"
                      addLabelClasses="text-right"
                      :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.observation')"
                      v-model="$v.Observation.$model"
                      v-uppercase
                      :is-valid="hasError($v.Observation)"
                      :invalid-feedback="errorMessage($v.Observation)"
                      :disabled="dataContainer?.FgCertified??false"
                    />
                    <CSelect 
                      v-else
                      size="sm"
                      :options="statusOptions"
                      :value.sync="Status"
                      :label="$t('label.status')"
                      :horizontal="{label:'col-sm-2 col-lg-4', input:'col-sm-10 col-lg-8'}"
                      addLabelClasses="required text-right"
                      :is-valid="statusSelectColor"
                      :disabled="dataContainer?.FgCertified??false"
                    />
                  </CCol>
                  <CCol sm="12" lg="6">
                    <CInput
                      size="sm"
                      v-uppercase
                      v-model="YardName"
                      class="mb-3"
                      :label="$t('label.yard')"
                      :horizontal="{label:'col-sm-12 col-lg-3 text-right', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.yard')"
                      :disabled="true"
                    >
                    </CInput>
                    <CSelect
                      size="sm"
                      v-model="$v.MachineId.$model"
                      :options="MachineOptions"
                      :label="$t('label.machine')"
                      :horizontal="{label:'col-sm-12 col-lg-3 text-right', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.select')"
                      :is-valid="hasError($v.MachineId)"
                      :invalid-feedback="errorMessage($v.MachineId)"
                      :disabled="dataContainer?.FgCertified??false"
                    />
                    <CInput
                      size="sm"
                      v-uppercase
                      v-model="$v.EirNumber.$model"
                      :label="$t('label.EirNumber')"
                      :horizontal="{label:'col-sm-12 col-lg-3 text-right', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.EirNumber')"
                      :is-valid="hasError($v.EirNumber)"
                      :invalid-feedback="errorMessage($v.EirNumber)"
                      :disabled="dataContainer?.FgCertified??false"
                    >
                    </CInput>
                    <CSelect 
                      v-if="!ValidateTpEirTransac"
                      size="sm"
                      :options="statusOptions"
                      :value.sync="Status"
                      :label="$t('label.status')"
                      :horizontal="{label:'col-sm-2 col-lg-3', input:'col-sm-10 col-lg-8'}"
                      addLabelClasses="required text-right"
                      :is-valid="statusSelectColor"
                      :disabled="dataContainer?.FgCertified??false"
                    />
                    <CTextarea
                      v-else
                      size="sm"
                      rows="2"
                      :label="$t('label.observation')"
                      addLabelClasses="text-right"
                      :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
                      :placeholder="$t('label.observation')"
                      v-model="$v.Observation.$model"
                      v-uppercase
                      :is-valid="hasError($v.Observation)"
                      :invalid-feedback="errorMessage($v.Observation)"
                      :disabled="dataContainer?.FgCertified??false"
                    />
                  </CCol>  
                </CRow>
              </CCard>
            </CTab>
          </CTabs>
        </CCol>
        <CCol sm="12">
          <CTabs variant="tabs" :active-tab="0">
            <CTab :title="$t('label.inspectionSeals')">
              <CCard class="card-border">
                <CRow class="justify-content-center px-3">
                  <CCol sm="12" lg="6">
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right">{{`${$t('label.Seal')} 1`}}</label>
                      <div class="input-group col-sm-12 col-lg-8">
                        <CInput
                          size="sm"
                          class="select-adjust pr-1"
                          v-uppercase
                          :disabled="true"
                          v-model="$v.Seal.Seal1.Seal.$model"
                          :is-valid="hasError($v.Seal.Seal1.Seal)"
                          style="width: 20%;"
                        >
                        </CInput>
                        <CSelect
                          size="sm"
                          class="col-sm-5 px-0 mb-0"
                          v-model="$v.Seal.Seal1.ConditionSeal.$model"
                          :options="ConditionOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.Seal.Seal1.ConditionSeal)"
                          @change="SelectCondition(Seal.Seal1, 1)"
                          :disabled="dataContainer?.FgCertified??false"
                        />
                        <CButton
                          v-if="Seal.Seal1.ConditionSeal && !ConditionWithoutSeal(Seal.Seal1.ConditionSeal)"
                          square
                          size="sm"
                          class="ml-1"
                          @click="(SealJson=Seal.Seal1,ModalSealActive=true)"
                          style="padding: 0.15rem 0.4rem;"
                          :disabled="dataContainer?.FgCertified??false"
                        >
                          <CIcon name="dobleChevron"/>
                        </CButton>
                      </div>
                    </div>

                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right">{{`${$t('label.Seal')} 2`}}</label>
                      <div class="input-group col-sm-12 col-lg-8">
                        <CInput
                          size="sm"
                          class="select-adjust pr-1"
                          v-uppercase
                          :disabled="true"
                          v-model="$v.Seal.Seal2.Seal.$model"
                          :is-valid="hasError($v.Seal.Seal2.Seal)"
                          style="width: 20%;"
                        >
                        </CInput>
                        <CSelect
                          size="sm"
                          class="col-sm-5 px-0 mb-0"
                          v-model="$v.Seal.Seal2.ConditionSeal.$model"
                          :options="ConditionOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.Seal.Seal2.ConditionSeal)"
                          @change="SelectCondition(Seal.Seal2, 2)"
                          :disabled="dataContainer?.FgCertified??false"
                        />
                        <CButton
                          v-if="Seal.Seal2.ConditionSeal && !ConditionWithoutSeal(Seal.Seal2.ConditionSeal)"
                          square
                          size="sm"
                          class="ml-1"
                          @click="(SealJson=Seal.Seal2,ModalSealActive=true)"
                          style="padding: 0.15rem 0.4rem;"
                          :disabled="dataContainer?.FgCertified??false"
                        >
                          <CIcon name="dobleChevron"/>
                        </CButton>
                      </div>
                    </div>
                  </CCol>
                  <CCol sm="12" lg="6">
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 3`}}</label>
                      <div class="input-group col-sm-12 col-lg-8">
                        <CInput
                          size="sm"
                          class="select-adjust pr-1"
                          v-uppercase
                          :disabled="true"
                          v-model="$v.Seal.Seal3.Seal.$model"
                          :is-valid="hasError($v.Seal.Seal3.Seal)"
                          style="width: 20%;"
                        >
                        </CInput>
                        <CSelect
                          size="sm"
                          class="col-sm-5 px-0 mb-0"
                          v-model="$v.Seal.Seal3.ConditionSeal.$model"
                          :options="ConditionOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.Seal.Seal3.ConditionSeal)"
                          @change="SelectCondition(Seal.Seal3, 3)"
                          :disabled="dataContainer?.FgCertified??false"
                        />
                        <CButton
                          v-if="Seal.Seal3.ConditionSeal && !ConditionWithoutSeal(Seal.Seal3.ConditionSeal)"
                          square
                          size="sm"
                          class="ml-1"
                          @click="(SealJson=Seal.Seal3,ModalSealActive=true)"
                          style="padding: 0.15rem 0.4rem;"
                          :disabled="dataContainer?.FgCertified??false"
                        >
                          <CIcon name="dobleChevron"/>
                        </CButton>
                      </div>
                    </div>
                    <div class="form-group form-row">
                      <label class="col-form-label-sm col-sm-12 col-lg-3 mb-0 text-right">{{`${$t('label.Seal')} 4`}}</label>
                      <div class="input-group col-sm-12 col-lg-8">
                        <CInput
                          size="sm"
                          class="select-adjust pr-1"
                          v-uppercase
                          :disabled="true"
                          v-model="$v.Seal.Seal4.Seal.$model"
                          :is-valid="hasError($v.Seal.Seal4.Seal)"
                          style="width: 20%;"
                        >
                        </CInput>
                        <CSelect
                          size="sm"
                          class="col-sm-5 px-0 mb-0"
                          v-model="$v.Seal.Seal4.ConditionSeal.$model"
                          :options="ConditionOptions"
                          :placeholder="$t('label.select')"
                          :is-valid="hasError($v.Seal.Seal4.ConditionSeal)"
                          @change="SelectCondition(Seal.Seal4, 4)"
                          :disabled="dataContainer?.FgCertified??false"
                        />
                        <CButton
                          v-if="Seal.Seal4.ConditionSeal && !ConditionWithoutSeal(Seal.Seal4.ConditionSeal)"
                          square
                          size="sm"
                          class="ml-1"
                          @click="(SealJson=Seal.Seal4,ModalSealActive=true)"
                          style="padding: 0.15rem 0.4rem;"
                          :disabled="dataContainer?.FgCertified??false"
                        >
                          <CIcon name="dobleChevron"/>
                        </CButton>
                      </div>
                    </div>
                  </CCol>
                  <CCol sm="12" class="text-invalid-feedback text-center"
                    v-if="!ValidateTpCargoStatus && Validate && !Seal.Seal1.Seal && !Seal.Seal2.Seal && !Seal.Seal3.Seal && !Seal.Seal4.Seal && this.dataContainer?.VisitCargoId"
                  >
                    <label>{{ $t('label.AtLeastSealRequired') }}</label>
                  </CCol>
                </CRow>
              </CCard>
            </CTab>
          </CTabs>
        </CCol>
        <CCol sm="12" class="d-flex align-items-center justify-content-end mb-2" v-if="!(dataContainer?.FgCertified)">
          <CButton
            square
            color="add"
            class="d-flex align-items-center"
            @click="Submit()"
            :disabled="isSubmit"
          >
            <CIcon name="checkAlt"/>{{$t('button.accept')}}
          </CButton>
        </CCol>
      </CRow>
      <ModalSeal
        :modal="ModalSealActive"
        :SealJson="SealJson"
        :isEdit="isEdit"
        @CloseModal="(ModalSealActive=false, SealJson={})"
        @UpdateSeal="UpdateSeal"
        @UpdateRegister="UpdateRegister"
      />
  </div>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import receptionsRegisterValidations from '@/_validations/yard-management/container/receptionsRegisterValidations';
import { DateFormater } from '@/_helpers/funciones';
import { mapState } from 'vuex';
import  ModalSeal  from '../../../modal-seal';
import moment from 'moment';

function data() {
  return {
    FgDeparture: false,
    isSubmit: false,
    ModalSealActive: false,
    Validate: false,
    SealJson: {},
    CargoJson: {},
    title: '',
    ItineraryId: '',
    ItineraryList: [],
    VisitId: '',
    VesselName: '',
    YardStatusSuggestTransactionId: '',
    TransactionList: [],
    YardName: '',
    YardId: '',
    VisitCargoInspectionId: '',
    MachineId: '',
    MachineList: [],
    EirNumber: '',
    Seal: {
      Seal1: {
        index: 1,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal2: {
        index: 2,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal3: {
        index: 3,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
      Seal4: {
        index: 4,
        CurrentSeal: '',
        Seal: '',
        NewSeal: '',
        ConditionSeal: '',
        SealAssignmentId: '',
        ObservationSeal: '',
        SealDocumentJson: [],
      },
    },
    ConditionList: [],
    Observation: '',
    ValidPreviousDate: '',
    ValidLaterDate: '',
    previousDate: '',
    laterDate: '',
    TransactionDate: '',
    CancellationDate: '',
    Status: 0,
  };
}

async function getYardStatusSuggestTransaction() {
  await this.$http.get(`YardStatusSuggestTransaction-by-YardCargoId?YardCargoId=${this.dataContainer.YardCargoId??''}&VisitCargoId=${this.dataContainer.VisitCargoId??''}&CargoId=${this.dataContainer.CargoId??''}&FgInsert=${this.isEdit? 0 : 1}&EventId=${process.env.VUE_APP_EVENT_RECEPTION}`)
    .then(response => {
      let List = response.data.data;
      this.TransactionList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function SealConditionList() {
  await this.$http.get('SealCondition-list')
    .then(response => {
      let List = response.data.data;
      this.ConditionList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function YardMachine() {
  await this.$http.get('MachineYard-list', { YardId: this.dataContainer.YardId, Filter: 'ACTIVO', CompanyBranchId: this.CompanyBranchId })
    .then(response => {
      let List = response.data.data;
      this.MachineList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

async function YardServiceItinerary() {
  await this.$http.get('YardServiceItinerary-by-CompanyBranchId', { CompanyBranchId: this.CompanyBranchId })
    .then(response => {
      let List = response.data.data;
      this.ItineraryList = List&&List.length!=0 ? List : [];
    })
    .catch( err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
}

function Submit() {
  try {
    this.$store.state.yardManagement.loading = true;
    this.isSubmit = true;
    this.Validate = true;
    this.$v.$touch();
    
    if (this.$v.$error){
      if(this.dataContainer?.VisitCargoId || this.ValidateTpEirTransac){
        if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) <= this.ValidPreviousDate) {
          throw this.$t('label.ErrorConfirmationDatePleaseCheck');
        }
      }else if (this.dataContainer.ItineraryId) {
        if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) <= this.ValidPreviousDate && this.dataContainer.LabelArrival) {
          throw `${this.$t('label.ErrorPleaseCheck')} ${this.dataContainer.LabelArrival}`;
        }
        if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) >= this.ValidLaterDate && this.dataContainer.LabelDeparture) {
          throw `${this.$t('label.ErrorPleaseCheck')} ${this.dataContainer.LabelDeparture}`;
        }
      }else{
        if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) <= this.ValidPreviousDate) {
          throw this.$t('label.ErrorYearOneCurrentDatePleaseCheck');
        }
      }
      if (this.TransactionDate && DateFormater.formatDateTimeWithoutSlash(this.TransactionDate) > this.ValidLaterDate ) {
        throw this.$t('label.ErrorCurrentDatedPleaseCheck');
      }
      throw this.$t('label.errorsPleaseCheck');
    }

    let TpEirTransac = this.TransactionList.find((item)=>{return item.TpEirTransacId === this.YardStatusSuggestTransactionId})
    
    let CargoJson = {
      VisitCargoId: this.VisitCargoId,
      ItineraryId: this.ItineraryId,
      YardId: this.YardId,
      YardCargoId: this.dataContainer.YardCargoId,
      VisitCargoInspectionId:this.VisitCargoInspectionId ?? '',
      YardStatusSuggestTransactionId: TpEirTransac?.YardStatusSuggestTransactionId ?? '',
      MachineId: this.MachineId,
      Seal1: this.Seal.Seal1.Seal,
      Seal1ConditionId: this.Seal.Seal1.ConditionSeal,
      Seal1AssignmentId: this.Seal.Seal1.SealAssignmentId,
      ObservationSeal1: this.Seal.Seal1.ObservationSeal,
      Seal1DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal1.ConditionSeal) ? [] : 
        this.Seal.Seal1.SealDocumentJson.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      Seal2: this.Seal.Seal2.Seal,
      Seal2ConditionId: this.Seal.Seal2.ConditionSeal,
      Seal2AssignmentId: this.Seal.Seal2.SealAssignmentId,
      ObservationSeal2: this.Seal.Seal2.ObservationSeal,
      Seal2DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal2.ConditionSeal) ? [] : 
        this.Seal.Seal2.SealDocumentJson.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      Seal3: this.Seal.Seal3.Seal,
      Seal3ConditionId: this.Seal.Seal3.ConditionSeal,
      Seal3AssignmentId: this.Seal.Seal3.SealAssignmentId,
      ObservationSeal3: this.Seal.Seal3.ObservationSeal,
      Seal3DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal3.ConditionSeal) ? [] : 
        this.Seal.Seal3.SealDocumentJson.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      Seal4: this.Seal.Seal4.Seal,
      Seal4ConditionId: this.Seal.Seal4.ConditionSeal,
      Seal4AssignmentId: this.Seal.Seal4.SealAssignmentId,
      ObservationSeal4: this.Seal.Seal4.ObservationSeal,
      Seal4DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal4.ConditionSeal) ? [] : 
        this.Seal.Seal4.SealDocumentJson.map(Document => {
          return{
            SealRoute: Document.SealRoute ?? '',
            FgActDocument: 1,
            Status: 1,
          } 
        }),
      TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.TransactionDate),
      Observation: this.Observation.trim(),
      EirNumber: this.EirNumber,
      Status: this.Status,
    }

    if (!this.dataContainer?.VisitCargoId || this.ValidateTpEirTransac) {
      CargoJson.CargoId = this.dataContainer.CargoId;
    }

    this.$http.put('YardCargoReception-update', CargoJson, { root: 'CargoJson' })
      .then((response) => {
        this.notifySuccess({text: response.data.data[0].Response});
        this.Reset();
        this.isSubmit = false;
        this.$emit("Update-list");
      }).catch((err) => {
        this.isSubmit = false;
        this.$store.state.yardManagement.loading = false;
        this.notifyError({text: err});
      })
  } catch (error) {
    this.isSubmit = false;
    this.$store.state.yardManagement.loading = false;
    this.notifyError({text: error});
  }
}

async function UpdateRegister(images, index) {
  this.$store.state.yardManagement.loading = true;

  switch (index) {
    case 1:
      this.CargoJson.Seal1DocumentJson = images;
      break;
    case 2:
      this.CargoJson.Seal2DocumentJson = images;
      break;
    case 3:
      this.CargoJson.Seal3DocumentJson = images;
      break;
    case 4:
      this.CargoJson.Seal4DocumentJson = images;
      break;
  }

  await this.$http.put('YardCargoReception-update', this.CargoJson, { root: 'CargoJson' })
    .then(async (response) => {
      await this.$emit("Update-list");
    })
    .catch((err) => {
      this.$store.state.yardManagement.loading = false;
      this.notifyError({text: err});
    })
}

function SelectCondition(Seal, index) {
  let WithoutSeal = this.ConditionWithoutSeal(Seal.ConditionSeal);
  if (process.env.VUE_APP_SEAL_CONDITION_INTACT == Seal.ConditionSeal.toUpperCase() || !Seal.ConditionSeal ||
    WithoutSeal) {
    let CurrentSeal = {
      index: index,
      CurrentSeal: Seal.CurrentSeal,
      Seal: WithoutSeal ? '' : Seal.CurrentSeal,
      NewSeal: '',
      ConditionSeal: Seal.ConditionSeal,
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: Seal.SealDocumentJson,
    }
    this.UpdateSeal(CurrentSeal);
  }
}

function ConditionWithoutSeal(ConditionSeal) {
  if (typeof ConditionSeal == 'string') {
    return process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL == ConditionSeal.toUpperCase();
  }else{
    return false;
  }
}

function validateDateRange(date) {
  return date < this.previousDate || date > this.laterDate;
}

function statusSelectColor() {
  return this.Status === 1;
}

function UpdateSeal(Update) {
  switch (Update.index) {
    case 1:
      this.Seal.Seal1 = Update;
      break;
    case 2:
      this.Seal.Seal2 = Update;
      break;
    case 3:
      this.Seal.Seal3 = Update;
      break;
    case 4:
      this.Seal.Seal4 = Update;
      break;
    default:
      break;
  }
}

function getdata(val) {
  this.VisitCargoId = this.dataContainer.VisitCargoId ?? '';
  this.ItineraryId = this.dataContainer.ItineraryId ?? '';
  this.YardName = this.dataContainer.YardName;
  this.YardId = this.dataContainer.YardId;
  this.YardStatusSuggestTransactionId = val.TpEirTransacId;
  this.MachineId = val.MachineId ?? '';
  this.EirNumber = val.EirNumber ?? '';
  this.TransactionDate = val.TransactionDate;
  this.Observation = val.Observation ?? '';
  this.VisitCargoInspectionId = val?.SealJson[0]?.VisitCargoInspectionId ?? '';
  this.VisitId = val.VisitId;
  this.Status = val.FgActYardCargo ? 1 : 0;

  this.Seal.Seal1.CurrentSeal = val?.SealJson[0]?.Seal1Json[0]?.Seal ?? '';
  this.Seal.Seal1.Seal = val?.SealJson[0]?.Seal1Json[0]?.Seal ?? '';
  this.Seal.Seal1.SealDocumentJson = val?.SealJson[0]?.Seal1Json[0]?.SealDocumentJson ?? [];
  this.Seal.Seal1.NewSeal = val.SealJson[0]?.Seal1Json[0]?.Seal ?? '';
  this.Seal.Seal1.ObservationSeal = val?.SealJson[0]?.Seal1Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal1.ConditionSeal = val?.SealJson[0]?.Seal1Json[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal1.SealAssignmentId = val?.SealJson[0]?.Seal1Json[0]?.SealAssignmentId ?? '';
  
  this.Seal.Seal2.CurrentSeal = val?.SealJson[0]?.Seal2Json[0]?.Seal ?? '';
  this.Seal.Seal2.Seal = val?.SealJson[0]?.Seal2Json[0]?.Seal ?? '';
  this.Seal.Seal2.SealDocumentJson = val?.SealJson[0]?.Seal2Json[0]?.SealDocumentJson ?? [];
  this.Seal.Seal2.NewSeal = val?.SealJson[0]?.Seal2Json[0]?.Seal ?? '';
  this.Seal.Seal2.ObservationSeal = val?.SealJson[0]?.Seal2Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal2.ConditionSeal = val?.SealJson[0]?.Seal2Json[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal2.SealAssignmentId = val?.SealJson[0]?.Seal2Json[0]?.SealAssignmentId ?? '';

  this.Seal.Seal3.CurrentSeal = val?.SealJson[0]?.Seal3Json[0]?.Seal ?? '';
  this.Seal.Seal3.Seal = val?.SealJson[0]?.Seal3Json[0]?.Seal ?? '';
  this.Seal.Seal3.SealDocumentJson = val?.SealJson[0]?.Seal3Json[0]?.SealDocumentJson ?? [];
  this.Seal.Seal3.NewSeal = val?.SealJson[0]?.Seal3Json[0]?.Seal ?? '';
  this.Seal.Seal3.ObservationSeal = val?.SealJson[0]?.Seal3Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal3.ConditionSeal = val?.SealJson[0]?.Seal3Json[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal3.SealAssignmentId = val?.SealJson[0]?.Seal3Json[0]?.SealAssignmentId ?? '';

  this.Seal.Seal4.CurrentSeal = val?.SealJson[0]?.Seal4Json[0]?.Seal ?? '';
  this.Seal.Seal4.Seal = val?.SealJson[0]?.Seal4Json[0]?.Seal ?? '';
  this.Seal.Seal4.SealDocumentJson = val?.SealJson[0]?.Seal4Json[0]?.SealDocumentJson ?? [];
  this.Seal.Seal4.NewSeal = val?.SealJson[0]?.Seal4Json[0]?.Seal ?? '';
  this.Seal.Seal4.ObservationSeal = val?.SealJson[0]?.Seal4Json[0]?.ObservationSeal ?? '';
  this.Seal.Seal4.ConditionSeal = val?.SealJson[0]?.Seal4Json[0]?.SealConditionId ?? process.env.VUE_APP_SEAL_CONDITION_WITHOUT_SEAL;
  this.Seal.Seal4.SealAssignmentId = val?.SealJson[0]?.Seal4Json[0]?.SealAssignmentId ?? '';

  if(this.dataContainer?.VisitCargoId || this.ValidateTpEirTransac){
    this.CancellationDate = DateFormater.formatDateTimeWithSlash(this.dataContainer.ConfirmationDate);
    this.previousDate = DateFormater.formatDateRange(this.dataContainer.ConfirmationDate);
    this.laterDate = new Date();
    this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.dataContainer.ConfirmationDate));
    this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatTimeZoneToDateTime(this.laterDate));
    this.VesselName = `${this.dataContainer.VesselName} - ${this.dataContainer.VoyageArrival} - ${DateFormater.formatDateTimeWithSlash(this.dataContainer.Ata)}`;
  }else{
    if (this.ItineraryId) {
      let CurrentDate = new Date();
      this.FgDeparture = moment(this.dataContainer.Departure).format('YYYY-MM-DD HH:mm')>moment(CurrentDate).format('YYYY-MM-DD HH:mm');
      this.laterDate = this.FgDeparture ? new Date() : DateFormater.formatDateRange(this.dataContainer.Departure);
      this.previousDate = DateFormater.formatDateRange(this.dataContainer.Arrival);
      this.ValidPreviousDate = moment(this.dataContainer.Arrival).format('YYYY-MM-DD HH:mm');
      this.ValidLaterDate = this.FgDeparture ? moment(CurrentDate).format('YYYY-MM-DD HH:mm') : moment(this.dataContainer.Departure).format('YYYY-MM-DD HH:mm');
    }else{
      this.previousDate =  moment(moment(new Date(),'YYYY-MM-DD HH:mm').subtract(12, 'M').format('DD/MM/YYYY') ,'DD/MM/YYYY').toDate();
      this.laterDate = new Date();
      this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatTimeZoneToDateTime(this.previousDate));
      this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatTimeZoneToDateTime(this.laterDate));
    }
  }

  
  this.CargoJson = {
    VisitCargoId: this.VisitCargoId,
    ItineraryId: this.ItineraryId,
    YardId: this.YardId,
    YardCargoId: this.dataContainer.YardCargoId,
    VisitCargoInspectionId:this.VisitCargoInspectionId ?? '',
    YardStatusSuggestTransactionId: this.YardStatusSuggestTransactionId,
    MachineId: this.MachineId,
    Seal1: this.Seal.Seal1.Seal,
    Seal1ConditionId: this.Seal.Seal1.ConditionSeal,
    Seal1AssignmentId: this.Seal.Seal1.SealAssignmentId,
    ObservationSeal1: this.Seal.Seal1.ObservationSeal,
    Seal1DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal1.ConditionSeal) ? [] : 
      this.Seal.Seal1.SealDocumentJson.map(Document => {
        return{
          SealRoute: Document.SealRoute ?? '',
          FgActDocument: 1,
          Status: 1,
        } 
      }),
    Seal2: this.Seal.Seal2.Seal,
    Seal2ConditionId: this.Seal.Seal2.ConditionSeal,
    Seal2AssignmentId: this.Seal.Seal2.SealAssignmentId,
    ObservationSeal2: this.Seal.Seal2.ObservationSeal,
    Seal2DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal2.ConditionSeal) ? [] : 
      this.Seal.Seal2.SealDocumentJson.map(Document => {
        return{
          SealRoute: Document.SealRoute ?? '',
          FgActDocument: 1,
          Status: 1,
        } 
      }),
    Seal3: this.Seal.Seal3.Seal,
    Seal3ConditionId: this.Seal.Seal3.ConditionSeal,
    Seal3AssignmentId: this.Seal.Seal3.SealAssignmentId,
    ObservationSeal3: this.Seal.Seal3.ObservationSeal,
    Seal3DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal3.ConditionSeal) ? [] : 
      this.Seal.Seal3.SealDocumentJson.map(Document => {
        return{
          SealRoute: Document.SealRoute ?? '',
          FgActDocument: 1,
          Status: 1,
        } 
      }),
    Seal4: this.Seal.Seal4.Seal,
    Seal4ConditionId: this.Seal.Seal4.ConditionSeal,
    Seal4AssignmentId: this.Seal.Seal4.SealAssignmentId,
    ObservationSeal4: this.Seal.Seal4.ObservationSeal,
    Seal4DocumentJson: this.ConditionWithoutSeal(this.Seal.Seal4.ConditionSeal) ? [] : 
      this.Seal.Seal4.SealDocumentJson.map(Document => {
        return{
          SealRoute: Document.SealRoute ?? '',
          FgActDocument: 1,
          Status: 1,
        } 
      }),
    TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.TransactionDate),
    Observation: this.Observation.trim(),
    EirNumber: this.EirNumber,
    Status: this.Status,
  }
  this.$v.$touch();
}

function Reset() {
  this.CargoJson = '';
  this.VisitCargoId = '';
  this.title = '';
  this.VisitId = '';
  this.ItineraryId = '';
  this.ItineraryList = [];
  this.YardStatusSuggestTransactionId = '';
  this.TransactionList = [];
  this.YardName = '';
  this.YardId = '';
  this.MachineId = '';
  this.MachineList = [];
  this.EirNumber = '';
  this.Seal = {
    Seal1: {
      index: 1,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal2: {
      index: 2,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal3: {
      index: 3,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
    Seal4: {
      index: 4,
      CurrentSeal: '',
      Seal: '',
      NewSeal: '',
      ConditionSeal: '',
      SealAssignmentId: '',
      ObservationSeal: '',
      SealDocumentJson: [],
    },
  };
  this.Observation = '',
  this.ValidPreviousDate = '';
  this.ValidLaterDate = '';
  this.previousDate = '';
  this.laterDate = '';
  this.TransactionDate = '';
  this.CancellationDate = '';
  this.Validate = false;
  this.FgDeparture = false;
  this.$v.$reset();
  this.$emit("Close");
}

//Computeds:
function ItineraryOptions() {
	if(this.ItineraryList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.ItineraryList.map(function(e){
      chart.push({
        value: e.ItineraryId,
        label: `${e.VesselName} - ${e.Voyage} - ${e.LabelArrival}: ${DateFormater.formatDateTimeWithSlash(e.Arrival)}`,
      })
    })
    return chart;
  }
}

function TransactionOptions() {
  if(this.TransactionList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    let _lang = this.$i18n.locale;
    this.TransactionList.map(function(e){
      chart.push({
        value: e.TpEirTransacId,
        label: _lang=='en'? e.TpTransacEirNameEn : e.TpTransacEirNameEs,
      })
    })
    return chart;
  }
}

function MachineOptions() {
  if(this.MachineList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.MachineList.map(function(e){
      chart.push({
        value: e.MachineId,
        label: e.MachineName,
      })
    })
    return chart;
  }
}

function ConditionOptions() {
  if(this.ConditionList.length === 0){
    return [{
      value: '', 
      label: this.$t('label.condition'),
    }];
  }else{
    let chart = [{
      value: '', 
      label: this.$t('label.condition'),
    }]
    let _lang = this.$i18n.locale;
    this.ConditionList.map(function(e){
      chart.push({
        value: e.SealConditionId,
        label: _lang=='en' ? e.SealConditionNameEn : e.SealConditionNameEs,
      })
    })
    return chart;
  }
}

function ValidateTpCargoStatus() {
  return process.env.VUE_APP_TP_CARGO_STATUS_EMPTY == this.dataContainer?.TpCargoStatusId?.toUpperCase();
}

function ValidateTpEirTransac() {
  return process.env.VUE_APP_TP_EIR_TRANSAC_EMPTY_RETURN == this.dataContainer?.TpEirTransacId?.toUpperCase();
}

function VerifySeal() {
  if (this.ValidateTpCargoStatus) {
    return {
      ConditionSeal1: false,
      ConditionSeal2: false,
      ConditionSeal3: false,
      ConditionSeal4: false,
    }
  } else if (!this.Seal.Seal1.Seal && !this.Seal.Seal2.Seal && !this.Seal.Seal3.Seal && !this.Seal.Seal4.Seal) {
      return {
        ConditionSeal1: true,
        ConditionSeal2: true,
        ConditionSeal3: true,
        ConditionSeal4: true,
      }
  } else {
    return {
      ConditionSeal1: this.Seal.Seal1.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal1.ConditionSeal) ? false : true,
      ConditionSeal2: this.Seal.Seal2.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal2.ConditionSeal) ? false : true,
      ConditionSeal3: this.Seal.Seal3.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal3.ConditionSeal) ? false : true,
      ConditionSeal4: this.Seal.Seal4.ConditionSeal&&this.ConditionWithoutSeal(this.Seal.Seal4.ConditionSeal) ? false : true,
    }
  }
}

export default {
  name: 'equipment-register',
  components: {
    ModalSeal,
   },
  props: { Active: Boolean, ReceptionItem: Object,  isEdit: Boolean },
  data,
  mixins: [
    ModalMixin,
    GeneralMixin
  ],
  validations(){
    return receptionsRegisterValidations(this.VerifySeal, this.ValidPreviousDate, this.ValidLaterDate, !this.dataContainer?.VisitCargoId || this.dataContainer?.VisitCargoId === null &&
      this.dataContainer.ItineraryId, this.FgDeparture);
  },
  directives: UpperCase,
  methods: {
    getYardStatusSuggestTransaction,
    SealConditionList,
    YardMachine,
    YardServiceItinerary,
    Submit,
    UpdateRegister,
    SelectCondition,
    ConditionWithoutSeal,
    validateDateRange,
    statusSelectColor,
    UpdateSeal,
    getdata,
    Reset,
  },
  computed: {
    ItineraryOptions,
    TransactionOptions,
    MachineOptions,
    ConditionOptions,
    ValidateTpCargoStatus,
    ValidateTpEirTransac,
    VerifySeal,
    ...mapState({
      dataContainer: state => state.yardManagement.dataContainer,
      CompanyBranchId: state => state.auth.branch.CompanyBranchId
    })
  },
  watch: {
    Active: async function(val){
      if (val) {
        this.$store.state.yardManagement.loading = true;
        await this.getYardStatusSuggestTransaction();
        await this.SealConditionList();
        await this.YardMachine();
        if (!this.dataContainer?.VisitCargoId && !this.ValidateTpEirTransac) {
          await this.YardServiceItinerary();
        }
        await this.getdata(this.ReceptionItem);
        this.$store.state.yardManagement.loading = false;
      }
    },
    TransactionDate: async function(val){
      try{
        if(val){
          if(this.dataContainer?.VisitCargoId || this.ValidateTpEirTransac){
            if (DateFormater.formatDateTimeWithoutSlash(val) <= this.ValidPreviousDate) {
              throw this.$t('label.ErrorConfirmationDatePleaseCheck');
            }
          }else if (this.dataContainer.ItineraryId) {
            if (DateFormater.formatDateTimeWithoutSlash(val) <= this.ValidPreviousDate && this.dataContainer.LabelArrival) {
              throw `${this.$t('label.ErrorPleaseCheck')} ${this.dataContainer.LabelArrival}`;
            }
            if (DateFormater.formatDateTimeWithoutSlash(val) >= this.ValidLaterDate && this.dataContainer.LabelDeparture && !this.FgDeparture) {
              throw `${this.$t('label.ErrorPleaseCheck')} ${this.dataContainer.LabelDeparture}`;
            }
          }else{
            if (DateFormater.formatDateTimeWithoutSlash(val) <= this.ValidPreviousDate) {
              throw this.$t('label.ErrorYearOneCurrentDatePleaseCheck');
            }
          }
          if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate) {
            throw this.$t('label.ErrorCurrentDatedPleaseCheck');
          }
        }
      }catch(e){
        this.notifyError({text: e});
      }
    },
  },
 
};
</script>
<style scoped>
.card-border{
  padding: 20px;
  background-color: #fff;
  border-color: #c4c9d0;
  border-top: none;
  border-radius: 0 0 0.25rem 0.25rem;
}
</style>